// Disappears when nav appears
import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import { showLogo } from "./Layout/NavWithLogo/styles"
import LogoWithLink from "./LogoWithLink"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      [up(showLogo)]: {
        display: "none",
      },
    },
    logo: {
      marginBottom: "6.5625rem",
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  children?: React.ReactNode
}

const HeroWithLogo = ({ classes, children = null }: Props) => {
  return (
    <div className={classes.root}>
      <LogoWithLink classes={{ root: classes.logo }} />
      {children}
    </div>
  )
}

export default withStyles(styles)(HeroWithLogo)
