import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import ExpandedContent from "../../Nav/ExpandedContent"
import LogoAndText from "./LogoAndText"
import { showLogo } from "../styles"
import LogoLink from "../../../LogoLink"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      [up(showLogo)]: {
        justifyContent: "space-between",
        alignItems: "flex-start",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const ExpandedContentWithLogo = ({ classes }: Props) => {
  return (
    <ExpandedContent classes={classes}>
      <LogoLink>
        <LogoAndText idPrefix="nav" />
      </LogoLink>
    </ExpandedContent>
  )
}

export default withStyles(styles)(ExpandedContentWithLogo)
