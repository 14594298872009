import React from "react"

import HeroLogoAndText from "./HeroLogoAndText"
import LogoLink, { Props } from "./LogoLink"

export default function LogoWithLink(props: Omit<Props, "children">) {
  return (
    <LogoLink {...props}>
      <HeroLogoAndText />
    </LogoLink>
  )
}
