import { withStyles, createStyles, Theme } from "@material-ui/core"

import LogoAndText from "../../../LogoAndText"
import { showLogo } from "../styles"

const styles = ({ breakpoints: { up } }: Theme) => {
  const showLogoUp = up(showLogo)
  return createStyles({
    root: {
      display: "none",
      [showLogoUp]: {
        display: "flex",
      },
    },
    logo: {
      [showLogoUp]: {
        width: "23.875rem",
      },
    },
  })
}

export default withStyles(styles)(LogoAndText)
