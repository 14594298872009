import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import Nav from "../Nav"
import ExpandedContentWithLogo from "./ExpandedContentWithLogo"
import { showLogo } from "./styles"
import { navWithLogoDimensions } from "../../../constants/heights"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      [up(showLogo)]: {
        marginBottom: navWithLogoDimensions.lg.marginBottom,
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const NavWithLogo = ({ classes }: Props) => {
  return <Nav expandedContent={<ExpandedContentWithLogo />} classes={classes} />
}

export default withStyles(styles)(NavWithLogo)
