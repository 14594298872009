import React from "react"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"
import { Link } from "gatsby"

import links from "../constants/links"

const styles = () => {
  return createStyles({
    root: {
      textDecoration: "none",
    },
  })
}

export interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

const LogoLink = ({ classes, children }: Props) => {
  return (
    <Link to={links.home} className={classes.root}>
      {children}
    </Link>
  )
}

export default withStyles(styles)(LogoLink)
